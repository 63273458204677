import * as React from "react";
import useSetState from "react-use/lib/useSetState";
import {
    GoogleLoginResponse,
    GoogleLoginResponseOffline,
} from "react-google-login";

import { FitplanError } from "@fitplan/context/lib-es/error";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import { IUser, loginSucceeded } from "@fitplan/context/lib-es/auth/types";
import { useStrings } from "@fitplan/context/lib-es/strings";

import { Field, validateEmail } from "./common";
import { sentryReportException } from "../../utils/sentry";
import { useAppleSSO } from "./useAppleSSO";

export interface SignUpStrings {
    firstNamePlaceholder: string;
    lastNamePlaceholder: string;
    emailPlaceholder: string;
    passwordPlaceholder: string;
    repeatPasswordPlaceholder: string;
    radioMaleText: string;
    radioFemaleText: string;
    radioOtherText: string;
    unknownError: string;
    facebookError: string;
    googleError: string;
    signUpError: string;
    facebookButton: string;
    googleButton: string;
    loginButton: string;
    spearatorText: string;
    title: string;
    footerText: string;
    loginLinkText: string;
    emailCheckbox: string;
    termsOfService: string;
    privacyPolicy: string;
    accountAgreement: string;
}

export interface SignUpState {
    firstName: Field;
    lastName: Field;
    email: Field;
    password: Field;
    confirmPassword: Field;
    emailCheckbox: Field;
    gender: {
        male: Field;
        female: Field;
        other: Field;
    };
    onSubmit: (event: React.SyntheticEvent) => void;
    errors: { [name: string]: string };
    isLoading: boolean;
    submitDisabled: boolean;
    globalError: string;
    facebookSignUp: {
        callback: (user: any) => void;
    };
    googleSignUp: {
        onSuccess: (
            response: GoogleLoginResponse | GoogleLoginResponseOffline
        ) => void;
        onFailure: (error: any) => void;
        disabled: boolean;
    };
}

export interface Props {
    partner?: string;
    onComplete: (user: IUser) => void;
    children: (input: SignUpState) => JSX.Element;
    redirectUri?: string;
}

interface State {
    values: { [name: string]: string };
    errors: { [name: string]: string };
    globalError?: string;
    isLoading: boolean;
    submitDisabled: boolean;
    submitted: boolean;
    googleDisabled: boolean;
}

export const SignUpController: React.FunctionComponent<Props> = props => {
    const [state, setState] = useSetState<State>({
        values: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            gender: "",
        },
        errors: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            gender: "",
        },
        isLoading: false,
        submitDisabled: false,
        googleDisabled: false,
        submitted: false,
    });
    const { signup, facebookLogin, googleLogin } = useAuthContext();
    const strings = useStrings("signUpPage");

    const controller = React.useMemo<SignUpState>(() => {
        const googleCallback = async (response: any) => {
            setState({ isLoading: true });
            if (!response || !response.tokenId) {
                setState({
                    globalError: strings.facebookError,
                    isLoading: false,
                });
                return;
            }
            const result = await googleLogin(
                (response as any).tokenId as string
            );
            if (loginSucceeded(result)) {
                setState({ isLoading: false });
                props.onComplete(result);
            } else {
                setState({ isLoading: false, globalError: result.message });
            }
        };

        const googleErrorCallback = (response: {
            details: string;
            error: string;
        }) => {
            if (
                response?.error === "popup_closed_by_user" ||
                response?.error === "access_denied"
            ) {
                return;
            } else if (response?.error === "idpiframe_initialization_failed") {
                setState({
                    globalError: "",
                    googleDisabled: true,
                    isLoading: false,
                });
            } else {
                sentryReportException(
                    new FitplanError("Google Login Error", response)
                );
                setState({
                    globalError: strings.googleError,
                    isLoading: false,
                });
            }
        };

        const facebookCallback = async (user: any) => {
            setState({ isLoading: true });
            if (!user || !user.accessToken) {
                setState({
                    globalError: strings.facebookError,
                    isLoading: false,
                });
                return;
            }
            const result = await facebookLogin(user.accessToken);
            if (loginSucceeded(result)) {
                setState({ isLoading: false });
                props.onComplete(result);
            } else {
                sentryReportException(
                    new FitplanError("Facebook Login Error", result)
                );
                setState({ isLoading: false, globalError: result.message });
            }
        };

        const onChange = (
            name: string
        ): ((event: React.SyntheticEvent<HTMLInputElement>) => void) => {
            return (event: React.SyntheticEvent<HTMLInputElement>) => {
                const input = event.target as HTMLInputElement;
                const { value, type, checked } = input;
                if (type === "checkbox") {
                    setState({
                        values: {
                            ...state.values,
                            [name]: checked ? "true" : "false",
                        },
                        errors: { ...state.errors, [name]: "" },
                    });
                } else {
                    setState({
                        values: { ...state.values, [name]: value },
                        errors: { ...state.errors, [name]: "" },
                    });
                }
            };
        };

        const onFocus = (name: string): (() => void) => {
            return () => {};
        };

        const onBlur = (name: string): (() => void) => {
            return () => {
                setState({
                    errors: {
                        ...state.errors,
                        [name]: validateField(name, state),
                    },
                });
            };
        };

        const validateField = (name: string, state: State): string => {
            switch (name) {
                case "firstName":
                    if (
                        !state.values.firstName ||
                        state.values.firstName.length === 0
                    ) {
                        return "Please enter your first name";
                    }
                    break;
                case "lastName":
                    if (
                        !state.values.lastName ||
                        state.values.lastName.length === 0
                    ) {
                        return "Please enter your last name";
                    }
                    break;
                case "email":
                    if (
                        !state.values.email ||
                        !validateEmail(state.values.email)
                    ) {
                        return "Please enter a valid email address";
                    }
                    break;
                case "password":
                    if (
                        !state.values.password ||
                        state.values.password.length < 6
                    ) {
                        return "Password must be at least 6 characters long";
                    }
                    break;
                case "confirmPassword":
                    if (
                        state.values.confirmPassword !== state.values.password
                    ) {
                        return "Passwords must match";
                    }
                    break;
                case "gender":
                    if (
                        !state.values.gender ||
                        state.values.gender.length < 1
                    ) {
                        return "Please select a gender";
                    }
                    break;
            }
            return "";
        };

        const validateAll = (state: State): { [field: string]: string } => {
            return {
                firstName: validateField("firstName", state),
                lastName: validateField("lastName", state),
                email: validateField("email", state),
                gender: validateField("gender", state),
                password: validateField("password", state),
                confirmPassword: validateField("confirmPassword", state),
            };
        };

        const onSubmit = async (event: React.SyntheticEvent): Promise<void> => {
            event.preventDefault();

            const errors: { [name: string]: string } = validateAll(state);
            if (
                Object.entries(errors).filter(([key, value]) => !!value)
                    .length === 0
            ) {
                setState({ isLoading: true });
                const result = await signup({
                    firstName: state.values.firstName,
                    lastName: state.values.lastName,
                    email: state.values.email,
                    gender: state.values.gender,
                    password: state.values.password,
                    partner: props.partner,
                });
                if (loginSucceeded(result)) {
                    setState({ isLoading: false });
                    props.onComplete(result);
                } else {
                    setState({ isLoading: false, globalError: result.message });
                }
            } else {
                setState({ errors });
            }
        };

        const email = validateField("email", state);
        const password = validateField("password", state);
        const confirmPassword = validateField("confirmPassword", state);
        const gender = validateField("gender", state);
        const firstName = validateField("firstName", state);
        const lastName = validateField("lastName", state);

        return {
            firstName: {
                value: state.values.firstName || "",
                onChange: onChange("firstName"),
                onBlur: onBlur("firstName"),
                onFocus: onFocus("firstName"),
                placeholder: "First Name",
                invalid: !!(
                    (state.submitted || state.values.firstName) &&
                    !firstName
                ),
            },
            lastName: {
                value: state.values.lastName || "",
                onChange: onChange("lastName"),
                onBlur: onBlur("lastName"),
                onFocus: onFocus("lastName"),
                placeholder: "Last Name",
                invalid: !!(
                    (state.submitted || state.values.lastName) &&
                    !lastName
                ),
            },
            email: {
                value: state.values.email || "",
                onChange: onChange("email"),
                onBlur: onBlur("email"),
                onFocus: onFocus("email"),
                placeholder: "E-Mail Address",
                invalid: !!((state.submitted || state.values.email) && !email),
            },
            emailCheckbox: {
                value: "emailCheckbox",
                checked: state.values.emailCheckbox === "true",
                onChange: onChange("emailCheckbox"),
                onBlur: onBlur("emailCheckbox"),
                onFocus: onFocus("emailCheckbox"),
            },
            gender: {
                male: {
                    value: "male",
                    checked: state.values.gender === "male",
                    onChange: onChange("gender"),
                    onBlur: onBlur("gender"),
                    onFocus: onFocus("gender"),
                    invalid: !!(state.submitted && !gender),
                },
                female: {
                    value: "female",
                    checked: state.values.gender === "female",
                    onChange: onChange("gender"),
                    onBlur: onBlur("gender"),
                    onFocus: onFocus("gender"),
                    invalid: !!(state.submitted && !gender),
                },
                other: {
                    value: "other",
                    checked: state.values.gender === "other",
                    onChange: onChange("gender"),
                    onBlur: onBlur("gender"),
                    onFocus: onFocus("gender"),
                    invalid: !!(state.submitted && !gender),
                },
            },
            password: {
                value: state.values.password || "",
                onChange: onChange("password"),
                onBlur: onBlur("password"),
                onFocus: onFocus("password"),
                placeholder: "Password",
                invalid: !!(
                    (state.submitted || state.values.password) &&
                    !password
                ),
            },
            confirmPassword: {
                value: state.values.confirmPassword || "",
                onChange: onChange("confirmPassword"),
                onBlur: onBlur("confirmPassword"),
                onFocus: onFocus("confirmPassword"),
                placeholder: "Confirm Password",
                invalid: !!(
                    (state.submitted || state.values.confirmPassword) &&
                    !confirmPassword
                ),
            },
            onSubmit: onSubmit,
            isLoading: state.isLoading,
            globalError: state.globalError || "",
            errors: state.errors,
            submitDisabled:
                state.isLoading ||
                Object.entries(validateAll(state)).filter(
                    ([key, value]) => !!value
                ).length > 0,
            facebookSignUp: {
                callback: facebookCallback,
            },
            googleSignUp: {
                onSuccess: googleCallback,
                onFailure: googleErrorCallback,
                disabled: state.googleDisabled,
            },
        };
    }, [state, props]);

    useAppleSSO({
        redirectUri: props.redirectUri,
        onComplete: props.onComplete,
        onError: (err) => setState({ globalError: typeof err === "string" ? `Apple Sign In Error: ${err}` : "Unknwon Apple Sign In error. Please try again later." }),
    });

    return props.children(controller);
};
