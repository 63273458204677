import * as React from "react";
import styled from "@emotion/styled";

import IFrameAuth from "../../components/app/IFrameAuth";
import { SignupFormStrings } from "../../components/strings/SignupFormStrings";
import { SignUpForm, SignUpFooter } from "@fitplan/lib/components/UserAuth/SignUpForm";

export interface Props {}

const SignUp: React.SFC<Props> = (props) => {
    return (
        <Container>
            <SignupFormStrings>
                <SignUpForm onComplete={(user) => {}} darkMode />
                <SignUpFooter link="/app/login" darkMode/>
            </SignupFormStrings>
            <IFrameAuth />
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100vh;
    background-color: black;
`;

export default SignUp;
